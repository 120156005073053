import React from 'react'

const Intership = () => {
  return (
    <div>
      <h3>Intership</h3>
    </div>
  )
}

export default Intership
