import React from 'react'

const CorporateSocialResponsibility = () => {
  return (
    <div>
      <h3>Corporate Social Responsibility</h3>
    </div>
  )
}

export default CorporateSocialResponsibility
