import React from 'react'

const CaseStudies = () => {
  return (
    <div>
      <h3>Case Studies</h3>
    </div>
  )
}

export default CaseStudies
